<template>
  <div>
    <DataClerk />
  </div>
</template>

<script>
import DataClerk from '@/infdb_components/DataClerk.vue'
export default {
  components: {
    DataClerk
  }
}
</script>