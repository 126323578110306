<template>
  <div>
    <DefaultChangePassword />
  </div>
</template>

<script>
import DefaultChangePassword from '@/infdb_components/DefaultChangePassword.vue'
export default {
  components: {
    DefaultChangePassword
  }
}
</script>