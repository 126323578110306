<template>
  <div>
    <Commodity />
  </div>
</template>

<script>
import Commodity from '@/infdb_components/Commodity.vue'
export default {
  components: {
    Commodity
  }
}
</script>