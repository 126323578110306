<template>
  <div>
    <SignIn />
  </div>
</template>

<script>
import SignIn from '@/infdb_components/SignIn.vue'
export default {
  components: {
    SignIn
  }
}
</script>