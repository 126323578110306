export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard1', link: '/dashboard'  },
    //{ icon: 'mdi-account-group', key: 'menu.blank', text: 'Cooperative Registration', link: '/cooperativeRegistration' },
    { icon: 'mdi-account-question', key: 'menu.blank', text: 'Farmers Verifier', link: '/farmersVerifier' },
    // { icon: 'mdi-sprout', key: 'menu.blank', text: 'Farmers Registration', link: '/farmersRegistration' },
    { icon: 'mdi-chart-bell-curve', key: 'menu.blank', text: 'Summaries', link: '/summary' },
    //{ icon: 'mdi-bell', key: 'menu.blank', text: 'Picture', link: '/picture' } ,
    { icon: 'mdi-logout', key: 'menu.blank', text: 'Logout', link: '/' } 
  ]
}]