<template>
  <div>
    <Cluster />
  </div>
</template>

<script>
import Cluster from '@/infdb_components/Cluster.vue'
export default {
  components: {
    Cluster
  }
}
</script>