export default {
  pitch: 1,
  rate: 1,
  synth: window.speechSynthesis,

  speak: function (text, fn) {
    const sInstance = new SpeechSynthesisUtterance(text)

    sInstance.onend = function (event) {
      //console.log('SpeechSynthesisUtterance.onEnd')
      if (typeof fn === 'function') fn()
    }
    
    sInstance.onerror = function (event) {
      console.error('SpeechSynthesisUtterance.onerror')
    }
    sInstance.pitch = this.pitch
    sInstance.rate = this.rate
    this.synth.speak(sInstance)
  }
}