<template>
  <div>
    <Dashboard />
  </div>
</template>

<script>
import Dashboard from '@/infdb_components/Dashboard.vue'
export default {
  components: {
    Dashboard
  }
}
</script>